import React from "react"
import { graphql } from "gatsby"
import Picture from "gatsby-image"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <h1>Contacts</h1>

      <h2>Owners' Mailing list</h2>
      <hr />
      <p>
        From time to time TCVOA circulate information to owners about issues
        ongoing on the development, matters arising with the factor, concerns
        raised by owners etc. We also hold an AGM in March. If you are not on
        our database you may miss out on having your say on matters which are
        important to you.
      </p>
      <p>
        Therefore if you own a property within the Caledonian Village and you're
        not receiving such updates, please email us your name and property
        address so that we may update our records.
      </p>

      <p>
        All data provided by you to TCVOA either directly, or via Trinity
        Factors will be processed in accordance with our legimate interest of
        communicating matters of importance to you. You are entitled to see a
        copy of your data upon request, or to ask that we remove you from our
        list.
      </p>

      <h2>Local Community</h2>
      <hr />
      <p>
        Cale Village is within the boundary of the{" "}
        <OutboundLink href="https://gorgiedalrycc.org.uk/about/">
          Gorgie Dalry Community Council (GDCC)
        </OutboundLink>{" "}
        which is an elected group of local people who care about and represent
        the community of Gorgie and Dalry, and want to make the area a better
        place to live.
      </p>

      <p>
        We live in the Sighthill/Gorgie ward of the city, represented by{" "}
        <OutboundLink href="https://democracy.edinburgh.gov.uk/mgUserInfo.aspx?UID=2653">
          four councillors
        </OutboundLink>{" "}
        who if contacted can help advocate or provide support regarding any
        matters relating to Edinburgh City Council.
      </p>

      <div
        style={{
          float: `left`,
          maxWidth: `510px`,
          paddingLeft: `20px`,
          paddingRight: `50px`,
        }}
      >
        <h2>Contact TCVOA</h2>
        <hr />
        <p>
          Although Trinity Factors should be your first contact in most cases,
          you can contact us by email at{" "}
          <a href="mailto:tcvoa.edinburgh@gmail.com">
            tcvoa.edinburgh@gmail.com
          </a>{" "}
          for information or assistance, or if you have knowledge of something
          you would like us to pass on to other owners. We aim to check emails at least once a fortnight.
        </p>
      </div>
      <div
        style={{
          float: `left`,
          maxWidth: `450px`,
          paddingLeft: `25px`,
          paddingRight: `97px`,
          backgroundColor: `#f4fdf4`,
        }}
      >
        <h2>Contact Trinity Factors</h2>
        <hr />
        <blockquote>
          <address>
            Trinity Factoring Services
            <br />
            209 Bruntsfield Place,
            <br />
            Edinburgh, EH10 4DH
            <br />
          </address>
          <p>
            Tel. <a href="tel:+441314479911">0131 447 9911</a>
          </p>
          <p>
            <a href="mailto:admin@trinityfactors.co.uk">
              admin@trinityfactors.co.uk
            </a>
          </p>
        </blockquote>
      </div>
      <div style={{ clear: `both` }}>
        <Picture
          className="calevillage-third-skyline.jpg"
          fluid={data.file.childImageSharp.fluid}
          alt="calevillage-third-skyline.jpg"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/calevillage-third-skyline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ContactPage
